<template>
  <div id="about" class="spacer10">
    <div class="container">
      <h1 class="bold size5 ta-center titleyellow">¡Marca la diferencia!</h1>
      <p class="spacebottom3 halfwhite size2 ta-center">
        Sigue nuestra guía y llegarás muy lejos
      </p>
      <div class="spacetop1 row ai-center jc-between flexcol-s">
        <div class="col6 col12-s ta-center-s">
          <h3 class="size35 bold titleyellow">¿Qué vas a lograr?</h3>
          <p class="size2 spacetop1 spacebottom3">
            ● Aprenderás a potenciar tu imagen personal y crear tu marca 
            personal para hacerte más visible en el mercado laboral.
            <br />
            ● Conocerás cómo obtener más oportunidades laborales.
            <br />
            ● Establecerás con claridad tus objetivos laborales para así elegir el empleo de tus sueños.
            <br />
            ● Incrementarás tus conocimientos en hablidades blandas y otras 
            herramientas de gestión personal para mostrarte como la mejor 
            opción para las empresas.
          </p>
        </div>

        <div class="col6 col12-s spacebottom2-s">
          <img src="../../../../assets/MasterClass/Imagen2.webp" class="img-gaa redondear-jose" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.font {
    font-family: Roboto !important;
}

.text-justify {
    text-align: justify !important;
}

.titleyellow {
  color: rgb(255, 193, 7);
}

.bg-yellow {
  background: linear-gradient(214.02deg, rgb(255, 193, 7) 6.04%, rgb(255, 193, 7) 92.95%);
}

.img-gaa{
  width: 100%;
}

.verde-mio{
  color: rgb(255, 193, 7);
}

.redondear-jose{
  border-radius: 5%;
}

.ai-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.jc-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nft-logo {
  width: 25%;
  display: block;
  margin: 0px auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.nombre{
  size: 1.6rem;
}

.size55 {
  font-size: 2.8rem;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.row {
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col5 {
  width: 38.66667%;
}

.col6 {
  width: 47%;
}

.col7 {
  width: 55.33333%;
}

.size2 {
  font-size: 1.1rem;
}

.spacer10 {
  padding: 5rem 0;
}

.redondear-jose{
  border-radius: 5%;
}


@media screen and (max-width: 1024px) {
  .col6-md {
    width: 47%;
  }

  .img-gaa {
    width: 100%;
  }

}

@media screen and (max-width: 768px) {
  .col12-s {
    width: 97%;
  }
  .col-reverse-s {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .jc-center-s {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .jc-between-s {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .ta-center-s {
    text-align: center;
  }
  .spacebottom3-s {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 414px) {
  .col6-xs {
    width: 47%;
  }

  .img-gaa {
    width: 100%;
  }

}

.spacebottom1 {
  margin-bottom: 1rem;
}

.spacebottom3 {
  margin-bottom: 3rem;
}

.bold {
  font-weight: bold;
}

.size5 {
  font-size: 2rem;
}

.ta-center {
  text-align: center;
}

.spacetop1 {
  margin-top: 1rem;
}

</style>