<template>
  <div>
    <MasterClassInfo1 />
    <MasterClassInfo2 />
  </div>
</template>
<script>
import MasterClassInfo1 from "@/components/public/MasterClass/Info1/index.vue";
import MasterClassInfo2 from "@/components/public/MasterClass/Info2/index.vue";

export default {
  data() {
    return {
      all_products: [],
      alert: true,
      title: "Aprende con nosotros",
      mode: "public",
      showPrivateNavbar: true,
      loading: true,
    };
  },
  methods: {
    async getProducts() {
      const { data } = await this.$axios.get(`course/list`);
      this.all_products = data;
    },
  },
  mounted() {
    this.getProducts();
  },

  components: {
    MasterClassInfo1,
    MasterClassInfo2,
  },

};
</script>
<style>
body {
  background-color: #f5f3f3;
}
</style>
