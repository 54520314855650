<template>
  <div id="about" class="spacer10">
    <div class="container">
      <div class="row jc-between ai-center">
        <div class="col5 col12-s ta-center-s">
          <h1 class="size55 bold spacebottom1 titleyellow">Programa formativo de profesionales de la bolsa de trabajo
            del IPSSOMA</h1>
          <p class="size2 spacebottom3 text-justify">
            <br />
            En el Instituto Peruano SSOMA, hemos diseñado para ti todo un programa
            formativo especializado en temas de innovación para potenciar tu empleabilidad
            en todas partes del mundo. Conéctate a nuestros talleres y encuentra el empleo
            de tus sueños para cumplir tus metas.
          </p>
          <!--
            <div class="row col8 col9-md jc-between spacebottom4 jc-evenly-s col12-s">
              <a href="#collections" class="btn bg-yellow size2 white">Comencemos</a>
            </div> -->
        </div>
        <div class="col7 col12-s spacebottom3-s">
          <img src="../../../../assets/MasterClass/Imagen1.webp" alt="" class="img-gaa redondear-jose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.font {
    font-family: Roboto !important;
}

.text-justify {
    text-align: justify !important;
}

.titleyellow {
  color: rgb(255, 193, 7);
}

.bg-yellow {
  background: linear-gradient(214.02deg, rgb(255, 193, 7) 6.04%, rgb(255, 193, 7) 92.95%);
}

.size2 {
  font-size: 1.1rem;
}

.img-gaa{
  width: 100%;
}

.nombre{
  size: 1.6rem;
}

.verde-mio{
  color: rgb(255, 193, 7);
}

.redondear-jose{
  border-radius: 5%;
}

.ai-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.jc-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.size55 {
  font-size: 2.8rem;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.row {
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col5 {
  width: 38.66667%;
}

.col6 {
  width: 47%;
}

.col7 {
  width: 55.33333%;
}

.spacer10 {
  padding: 5rem 0;
}

.redondear-jose{
  border-radius: 5%;
}

@media screen and (max-width: 1024px) {
  .col6-md {
    width: 47%;
  }

  .img-gaa {
    width: 100%;
  }

}

@media screen and (max-width: 768px) {
  .col12-s {
    width: 97%;
  }
  .col-reverse-s {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .jc-center-s {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .jc-between-s {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .ta-center-s {
    text-align: center;
  }
  .spacebottom3-s {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 414px) {
  .img-gaa {
    width: 100%;
  }
}

.spacebottom1 {
  margin-bottom: 1rem;
}

.spacebottom3 {
  margin-bottom: 3rem;
}

.bold {
  font-weight: bold;
}

</style>